<template>
  <div class="box-status">
    <!-- index:main:isOpen START -->
    <span
      style="font-size: 24px; margin-top: 1.5rem;"
      v-html="'AppCheckin - SmsCheckin - CardCheckin - NfcCheckin'"
      v-if="boxOpened.isOpen"></span>

    <div
      class="description"
      v-if="boxOpened.isOpen"
    >
      <span
        v-if="box.hasAppCheckin === 0 && box.hasSmsCheckin === 0
        && box.hasCardCheckin === 0 && box.hasNfcCheckin === 0"
        v-html="'0 - 0 - 0 - 0'"
      ></span>
      <span
        v-if="box.hasAppCheckin === 0 && box.hasSmsCheckin === 0
        && box.hasCardCheckin === 0 && box.hasNfcCheckin === 1"
        v-html="'0 - 0 - 0 - 1'"
      ></span>
      <span
        v-if="box.hasAppCheckin === 0 && box.hasSmsCheckin === 0
        && box.hasCardCheckin === 1 && box.hasNfcCheckin === 0"
        v-html="'0 - 0 - 1 - 0'"
      ></span>
      <span
        v-if="box.hasAppCheckin === 0 && box.hasSmsCheckin === 0
        && box.hasCardCheckin === 1 && box.hasNfcCheckin === 1"
        v-html="'0 - 0 - 1 - 1'"
      ></span>
      <span
        v-if="box.hasAppCheckin === 0 && box.hasSmsCheckin === 1
        && box.hasCardCheckin === 0 && box.hasNfcCheckin === 0"
        v-html="'0 - 1 - 0 - 0'"
      ></span>
      <span
        v-if="box.hasAppCheckin === 0 && box.hasSmsCheckin === 1
        && box.hasCardCheckin === 0 && box.hasNfcCheckin === 1"
        v-html="'0 - 1 - 0 - 1'"
      ></span>
      <span
        v-if="box.hasAppCheckin === 0 && box.hasSmsCheckin === 1
        && box.hasCardCheckin === 1 && box.hasNfcCheckin === 0"
        v-html="'0 - 1 - 1 - 0'"
      ></span>
      <span
        v-if="box.hasAppCheckin === 0 && box.hasSmsCheckin === 1
        && box.hasCardCheckin === 1 && box.hasNfcCheckin === 1"
        v-html="'0 - 1 - 1 - 1'"
      ></span>
      <span
        v-if="box.hasAppCheckin === 1 && box.hasSmsCheckin === 0
        && box.hasCardCheckin === 0 && box.hasNfcCheckin === 0"
        v-html="'1 - 0 - 0 - 0'"
      ></span>
      <span
        v-if="box.hasAppCheckin === 1 && box.hasSmsCheckin === 0
        && box.hasCardCheckin === 0 && box.hasNfcCheckin === 1"
        v-html="'1 - 0 - 0 - 1'"
      ></span>
      <span
        v-if="box.hasAppCheckin === 1 && box.hasSmsCheckin === 0
        && box.hasCardCheckin === 1 && box.hasNfcCheckin === 0"
        v-html="'1 - 0 - 1 - 0'"
      ></span>
      <span
        v-if="box.hasAppCheckin === 1 && box.hasSmsCheckin === 0
        && box.hasCardCheckin === 1 && box.hasNfcCheckin === 1"
        v-html="'1 - 0 - 1 - 1'"
      ></span>
      <span
        v-if="box.hasAppCheckin === 1 && box.hasSmsCheckin === 1
        && box.hasCardCheckin === 0 && box.hasNfcCheckin === 0"
        v-html="'1 - 1 - 0 - 0'"
      ></span>
      <span
        v-if="box.hasAppCheckin === 1 && box.hasSmsCheckin === 1
        && box.hasCardCheckin === 0 && box.hasNfcCheckin === 1"
        v-html="'1 - 1 - 0 - 1'"
      ></span>
      <span
        v-if="box.hasAppCheckin === 1 && box.hasSmsCheckin === 1
        && box.hasCardCheckin === 1 && box.hasNfcCheckin === 0"
        v-html="'1 - 1 - 1 - 0'"
      ></span>
      <span
        v-if="box.hasAppCheckin === 1 && box.hasSmsCheckin === 1
        && box.hasCardCheckin === 1 && box.hasNfcCheckin === 1"
        v-html="'1 - 1 - 1 - 1'"
      ></span>

    </div>
    <!-- index:main:isOpen END -->

    <!-- index:main:isClosed START -->
    <div
      class="closed"
      v-else
    >
      <!-- index:main:isClosed:times START -->
      <div
        class="opening-hours"
        v-if="boxOpened.status === 'CLOSED'"
      >
        <h4>CLOSED - Öffunungszeiten</h4>

        <div
          class="hours"
          v-for="(day, dayIndex) in box.openingHours"
          :key="`weekday-day-${dayIndex}`"
        >
          <span
            v-html="`<span class='weekday-name'>${weekdayName(day.weekday)}</span>:
            ${$date(day.timeFrom, 'HH:mm:ss').format('HH:mm')}
             - ${$date(day.timeTo, 'HH:mm:ss').format('HH:mm')}`"
          ></span>
        </div>
      </div>
      <!-- index:main:isClosed:times END -->

      <!-- index:main:isClosed:maintenance START -->
      <div
        class="maintenance"
        v-if="boxOpened.status === 'MAINTENANCE'"
      >
        <h4>CLOSED - Wartung</h4>
      </div>
      <!-- index:main:isClosed:maintenance END -->

      <!-- index:main:isClosed:disturbance START -->
      <div
        class="disturbance"
        v-if="boxOpened.status === 'DISTURBANCE'"
      >
        <h4>CLOSED - Störung</h4>
      </div>
      <!-- index:main:isClosed:disturbance END -->

    </div>
    <!-- index:main:isClosed END -->
  </div>
</template>

<script>
export default {
  name: 'box-status',
  props: {
    box: {
      type: Object,
    },
    boxOpened: {
      type: Object,
    },
  },
  methods: {
    weekdayName(weekdayNumber) {
      const days = [
        {
          weekday: 1,
          name: `${this.$t('date.sunday')}`,
        },
        {
          weekday: 2,
          name: `${this.$t('date.monday')}`,
        },
        {
          weekday: 3,
          name: `${this.$t('date.tuesday')}`,
        },
        {
          weekday: 4,
          name: `${this.$t('date.wednesday')}`,
        },
        {
          weekday: 5,
          name: `${this.$t('date.thursday')}`,
        },
        {
          weekday: 6,
          name: `${this.$t('date.friday')}`,
        },
        {
          weekday: 7,
          name: `${this.$t('date.saturday')}`,
        },
      ];
      return days.filter((day) => weekdayNumber === day.weekday).map((day) => day.name);
    },
  },
};
</script>
