var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"box-status"},[(_vm.boxOpened.isOpen)?_c('span',{staticStyle:{"font-size":"24px","margin-top":"1.5rem"},domProps:{"innerHTML":_vm._s('AppCheckin - SmsCheckin - CardCheckin - NfcCheckin')}}):_vm._e(),(_vm.boxOpened.isOpen)?_c('div',{staticClass:"description"},[(_vm.box.hasAppCheckin === 0 && _vm.box.hasSmsCheckin === 0
      && _vm.box.hasCardCheckin === 0 && _vm.box.hasNfcCheckin === 0)?_c('span',{domProps:{"innerHTML":_vm._s('0 - 0 - 0 - 0')}}):_vm._e(),(_vm.box.hasAppCheckin === 0 && _vm.box.hasSmsCheckin === 0
      && _vm.box.hasCardCheckin === 0 && _vm.box.hasNfcCheckin === 1)?_c('span',{domProps:{"innerHTML":_vm._s('0 - 0 - 0 - 1')}}):_vm._e(),(_vm.box.hasAppCheckin === 0 && _vm.box.hasSmsCheckin === 0
      && _vm.box.hasCardCheckin === 1 && _vm.box.hasNfcCheckin === 0)?_c('span',{domProps:{"innerHTML":_vm._s('0 - 0 - 1 - 0')}}):_vm._e(),(_vm.box.hasAppCheckin === 0 && _vm.box.hasSmsCheckin === 0
      && _vm.box.hasCardCheckin === 1 && _vm.box.hasNfcCheckin === 1)?_c('span',{domProps:{"innerHTML":_vm._s('0 - 0 - 1 - 1')}}):_vm._e(),(_vm.box.hasAppCheckin === 0 && _vm.box.hasSmsCheckin === 1
      && _vm.box.hasCardCheckin === 0 && _vm.box.hasNfcCheckin === 0)?_c('span',{domProps:{"innerHTML":_vm._s('0 - 1 - 0 - 0')}}):_vm._e(),(_vm.box.hasAppCheckin === 0 && _vm.box.hasSmsCheckin === 1
      && _vm.box.hasCardCheckin === 0 && _vm.box.hasNfcCheckin === 1)?_c('span',{domProps:{"innerHTML":_vm._s('0 - 1 - 0 - 1')}}):_vm._e(),(_vm.box.hasAppCheckin === 0 && _vm.box.hasSmsCheckin === 1
      && _vm.box.hasCardCheckin === 1 && _vm.box.hasNfcCheckin === 0)?_c('span',{domProps:{"innerHTML":_vm._s('0 - 1 - 1 - 0')}}):_vm._e(),(_vm.box.hasAppCheckin === 0 && _vm.box.hasSmsCheckin === 1
      && _vm.box.hasCardCheckin === 1 && _vm.box.hasNfcCheckin === 1)?_c('span',{domProps:{"innerHTML":_vm._s('0 - 1 - 1 - 1')}}):_vm._e(),(_vm.box.hasAppCheckin === 1 && _vm.box.hasSmsCheckin === 0
      && _vm.box.hasCardCheckin === 0 && _vm.box.hasNfcCheckin === 0)?_c('span',{domProps:{"innerHTML":_vm._s('1 - 0 - 0 - 0')}}):_vm._e(),(_vm.box.hasAppCheckin === 1 && _vm.box.hasSmsCheckin === 0
      && _vm.box.hasCardCheckin === 0 && _vm.box.hasNfcCheckin === 1)?_c('span',{domProps:{"innerHTML":_vm._s('1 - 0 - 0 - 1')}}):_vm._e(),(_vm.box.hasAppCheckin === 1 && _vm.box.hasSmsCheckin === 0
      && _vm.box.hasCardCheckin === 1 && _vm.box.hasNfcCheckin === 0)?_c('span',{domProps:{"innerHTML":_vm._s('1 - 0 - 1 - 0')}}):_vm._e(),(_vm.box.hasAppCheckin === 1 && _vm.box.hasSmsCheckin === 0
      && _vm.box.hasCardCheckin === 1 && _vm.box.hasNfcCheckin === 1)?_c('span',{domProps:{"innerHTML":_vm._s('1 - 0 - 1 - 1')}}):_vm._e(),(_vm.box.hasAppCheckin === 1 && _vm.box.hasSmsCheckin === 1
      && _vm.box.hasCardCheckin === 0 && _vm.box.hasNfcCheckin === 0)?_c('span',{domProps:{"innerHTML":_vm._s('1 - 1 - 0 - 0')}}):_vm._e(),(_vm.box.hasAppCheckin === 1 && _vm.box.hasSmsCheckin === 1
      && _vm.box.hasCardCheckin === 0 && _vm.box.hasNfcCheckin === 1)?_c('span',{domProps:{"innerHTML":_vm._s('1 - 1 - 0 - 1')}}):_vm._e(),(_vm.box.hasAppCheckin === 1 && _vm.box.hasSmsCheckin === 1
      && _vm.box.hasCardCheckin === 1 && _vm.box.hasNfcCheckin === 0)?_c('span',{domProps:{"innerHTML":_vm._s('1 - 1 - 1 - 0')}}):_vm._e(),(_vm.box.hasAppCheckin === 1 && _vm.box.hasSmsCheckin === 1
      && _vm.box.hasCardCheckin === 1 && _vm.box.hasNfcCheckin === 1)?_c('span',{domProps:{"innerHTML":_vm._s('1 - 1 - 1 - 1')}}):_vm._e()]):_c('div',{staticClass:"closed"},[(_vm.boxOpened.status === 'CLOSED')?_c('div',{staticClass:"opening-hours"},[_c('h4',[_vm._v("CLOSED - Öffunungszeiten")]),_vm._l((_vm.box.openingHours),function(day,dayIndex){return _c('div',{key:("weekday-day-" + dayIndex),staticClass:"hours"},[_c('span',{domProps:{"innerHTML":_vm._s(("<span class='weekday-name'>" + (_vm.weekdayName(day.weekday)) + "</span>:\n          " + (_vm.$date(day.timeFrom, 'HH:mm:ss').format('HH:mm')) + "\n           - " + (_vm.$date(day.timeTo, 'HH:mm:ss').format('HH:mm'))))}})])})],2):_vm._e(),(_vm.boxOpened.status === 'MAINTENANCE')?_c('div',{staticClass:"maintenance"},[_c('h4',[_vm._v("CLOSED - Wartung")])]):_vm._e(),(_vm.boxOpened.status === 'DISTURBANCE')?_c('div',{staticClass:"disturbance"},[_c('h4',[_vm._v("CLOSED - Störung")])]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }